import React, { useCallback } from 'react';

import { useNavigate } from "react-router-dom";

import {
    School as SchoolIcon,
    CalendarMonth as CalendarIcon,
    Book as BookIcon,
    Logout as LogoutIcon
} from "@mui/icons-material";

import {
    AppbarContainer,
    AppbarLogo,
    AppbarMenu,
} from './style'

import { Button, IconButton } from '@mui/material';
import { useUIContext } from '../../context/ui';

interface Props {
    matches: boolean;
}

const AppBarAdminDesktop: React.FC<Props> = ({ matches }) => {
    const navigate = useNavigate();
    const { setIsAuthenticated, setIsAdmin, setIsLogin, userData } = useUIContext();

    const handleLogout = useCallback(() => {
        setIsAuthenticated(false)
        setIsAdmin(false);
        setIsLogin(false);
        navigate("/", { replace: true });
    }, [setIsAuthenticated, setIsAdmin, setIsLogin, navigate])


    return (
        <AppbarContainer>
            <AppbarLogo>
                <img src="/logo.png" alt="" width={180} />
            </AppbarLogo>
            <AppbarMenu>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                    <div>
                        <Button
                            variant="text"

                            startIcon={<BookIcon />}
                            onClick={() => navigate("/admin/estudos")}>
                            ESTUDOS
                        </Button>

                        <Button
                            variant="text"

                            startIcon={<SchoolIcon />}
                            onClick={() => navigate("/admin/cursos")}
                        >
                            CURSOS
                        </Button>

                        <Button
                            variant="text"

                            startIcon={<CalendarIcon />}
                            onClick={() => navigate("/admin/agenda")}
                        >
                            AGENDA
                        </Button>
                    </div>
                    {userData && (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>

                                <div style={{ marginRight: 15 }}>
                                    <span style={{ fontWeight: 800 }}>{userData.username}</span>
                                </div>

                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="logout"
                                    onClick={handleLogout}
                                >
                                    <LogoutIcon />
                                </IconButton>
                            </div>
                        </>
                    )}
                </div>
            </AppbarMenu>
        </AppbarContainer>
    );
}

export default AppBarAdminDesktop;