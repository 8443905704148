import { ReactNode } from 'react';
import AppDrawer from '../components/AppDrawer';
import AppFooter from '../components/AppFooter';
import { useUIContext } from '../context/ui';
import Theme01 from './Themes/Theme01';

function Master({ children }: { children: ReactNode; }): JSX.Element {
    const { isLogin, isAdmin } = useUIContext()

    return (
        <>
            <Theme01>
                {children}
            </Theme01>
            {isLogin === false && isAdmin === false && (
                <>
                    <AppDrawer />
                    <AppFooter />
                </>
            )}
        </>

    );
}

export default Master;