import React from 'react';

import { useNavigate } from "react-router-dom";

import {
    Toolbar,
    Typography,
    Grid,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    IconButton,
    Button,
    Divider,
} from '@mui/material'

import {
    Comment as CommentIcon
} from '@mui/icons-material';

import {
    Root,
    ContainerHead,
    OrixasText,
    CardDetails,
} from './styles'

const sections = [
    "Oxalá",
    "Ogun",
    "Abaluaiê",
    "Xangô",
    "Yasan",
    "Oxossi",
    "Nanan",
    "Yemanjá",
    "Oxum",
    "Entidades"
];

const featuredPosts = [
    {
        title: "Bater Cabeça",
        date: "13/06/22",
        description:
            "O ato de bater cabeça é a oportunidade que todo o terreiro (médium, ogã, cabone, sacerdote) tem de estabelecer a conexão com os guias e divindades antes mesmo de tomar o passe ou incorporar. O altar é o local do templo onde se encontra a maior concentração energética pura vinda dos assentamentos e das irradiações fluídicas dos Orixás."
    },
    {
        title: "Cavalo",
        date: "13/06/22",
        description:
            "Nos primórdios da Umbanda, os médiuns de incorporação eram chamados pelos guias de cavalos. Hoje muitos umbandistas criticam esse nome, por acreditar ser uma denominação grotesca e o termo vem sendo substituído simplesmente por “médium”"
    },
    {
        title: "Cigano Milonário",
        date: "13/06/22",
        description:
            "Os Ciganos – mentores espirituais – que trazem o ouro, moeda ou alguma forma de riqueza em seu nome, como o Cigano Milionário, Cigano do Ouro, a Cigana do Ouro, a própria Cigana Esmeralda, trazem um elemento de valor para nós, mas esse é apenas seu objeto de trabalho. O nome do cigano traz um ponto de força vinculado a um Orixá e, naturalmente, esse vínculo traz uma linhagem e um elemento de trabalho"
    },
    {
        title: "Cigano Milonário",
        date: "13/06/22",
        description:
            "Os Ciganos – mentores espirituais – que trazem o ouro, moeda ou alguma forma de riqueza em seu nome, como o Cigano Milionário, Cigano do Ouro, a Cigana do Ouro, a própria Cigana Esmeralda, trazem um elemento de valor para nós, mas esse é apenas seu objeto de trabalho. O nome do cigano traz um ponto de força vinculado a um Orixá e, naturalmente, esse vínculo traz uma linhagem e um elemento de trabalho"
    },
    {
        title: "Cigano Milonário",
        date: "13/06/22",
        description:
            "Os Ciganos – mentores espirituais – que trazem o ouro, moeda ou alguma forma de riqueza em seu nome, como o Cigano Milionário, Cigano do Ouro, a Cigana do Ouro, a própria Cigana Esmeralda, trazem um elemento de valor para nós, mas esse é apenas seu objeto de trabalho. O nome do cigano traz um ponto de força vinculado a um Orixá e, naturalmente, esse vínculo traz uma linhagem e um elemento de trabalho"
    },
    {
        title: "Cigano Milonário",
        date: "13/06/22",
        description:
            "Os Ciganos – mentores espirituais – que trazem o ouro, moeda ou alguma forma de riqueza em seu nome, como o Cigano Milionário, Cigano do Ouro, a Cigana do Ouro, a própria Cigana Esmeralda, trazem um elemento de valor para nós, mas esse é apenas seu objeto de trabalho. O nome do cigano traz um ponto de força vinculado a um Orixá e, naturalmente, esse vínculo traz uma linhagem e um elemento de trabalho"
    }
];

const StudiesMobile: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Root>
                <ContainerHead>
                    <Typography variant='h4' color="inherit" noWrap >
                        ESTUDOS 7E
                    </Typography>
                </ContainerHead>
                <Toolbar variant="dense" style={{ justifyContent: 'space-between', overflow: 'auto', userSelect: 'none' }}>
                    {sections.map(section => (
                        <OrixasText key={section}>
                            {section}
                        </OrixasText>
                    ))}
                </Toolbar>
                <br />
                <Grid container>
                    {/* <Grid item xs={12} md={3}>
                        <div style={{ marginLeft: 16 }} >
                            <Typography variant='h6' color="inherit" noWrap >
                                DIAS DOS ESTUDOS
                            </Typography>

                        </div>
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 360,
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 300,
                                '& ul': { padding: 0 },
                            }}
                            subheader={<li />}
                        >
                            {['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'].map((sectionId) => (
                                <li key={`section-${sectionId}`}>
                                    <ul>
                                        <ListSubheader>{`${sectionId} - 2022`}</ListSubheader>
                                        {['13', '20'].map((item) => (
                                            <ListItem
                                                key={`item-${sectionId}-${item}`}
                                                secondaryAction={
                                                    <IconButton aria-label="comment">
                                                        <CommentIcon />
                                                    </IconButton>
                                                }
                                            >
                                                <ListItemText primary={`Dia ${item}`} />
                                            </ListItem>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </List>
                    </Grid> */}
                    <Divider light />
                    <Grid item xs={12} md={12} >
                        <div style={{ marginLeft: 16 }} >
                            <Typography variant='h6' color="inherit" noWrap >
                                TEXTOS
                            </Typography>
                        </div>
                        <Grid container >
                            {featuredPosts.map(post => (
                                <Grid item key={post.title} xs={12} md={12}>
                                    <Card sx={{ mb: 1 }}>
                                        <CardDetails>
                                            <CardContent>
                                                <Button variant="text">
                                                    <Typography variant="h5" color="primary">{post.title}</Typography>
                                                </Button>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {post.date}
                                                </Typography>
                                                <Typography variant="subtitle2" paragraph>
                                                    {post.description}
                                                </Typography>
                                                <Button
                                                    variant="text"
                                                    onClick={() => navigate("/estudo/6dasda65d4a5d56d4asd564da6")}
                                                >
                                                    <Typography variant="subtitle2" color="primary">
                                                        Continue lendo...
                                                    </Typography>
                                                </Button>
                                            </CardContent>
                                        </CardDetails>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Root>
        </>
    );
}

export default StudiesMobile;

