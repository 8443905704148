import React, { useCallback, useEffect, useState } from "react";

import ImageGallery from "react-image-gallery";
import api from "../../service";

import { PromotionsContainer } from "./styles";

interface Images {
  original: string;
  redirect: string;
}

const CarouselDesktop: React.FC = () => {
  const [imageCurrtent, setImageCurrtent] = useState<number>(0)
  const [images, setImage] = useState<Images[]>([])

  const getImage = useCallback(async () => {
    const { data } = await api.get('/site/highlight/1')
    setImage(data.galleries)
    console.log('getImage::', data);
  }, []);

  const handleUrl = useCallback(() => {
    console.log('handleUrl', images[imageCurrtent]);
  }, [images, imageCurrtent]);

  useEffect(() => {
    getImage()
  }, [getImage])

  return (
    <>
      <PromotionsContainer>
        <ImageGallery
          showPlayButton={true}
          showFullscreenButton={false}
          showThumbnails={false}
          showBullets
          showNav={false}
          autoPlay
          startIndex={0}
          items={images}
          onClick={handleUrl}
          onSlide={(current) => setImageCurrtent(current)}
        />
      </PromotionsContainer>
    </>
  );
};

export default CarouselDesktop;
