import React from 'react';

import {
    Menu as MenuIcon
} from "@mui/icons-material";

import {
    AppbarContainer, AppbarLogoMobile,
    AppbarMenuMobile
} from './style';

import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUIContext } from '../../context/ui';

interface Props {
    matches: boolean;
}

const AppBarMobile: React.FC<Props> = ({ matches }) => {
    const { setDrawerOpen } = useUIContext();
    const navigate = useNavigate();

    return (
        <AppbarContainer>
            <AppbarMenuMobile>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => setDrawerOpen(true)}
                >
                    <MenuIcon />
                </IconButton>
                <AppbarLogoMobile onClick={() => navigate('/')}>
                    <img src="/logo_7E.png" alt="" width={65} />
                </AppbarLogoMobile>
                <div></div>
            </AppbarMenuMobile>
        </AppbarContainer >
    );
}

export default AppBarMobile;