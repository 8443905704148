import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Colors } from "../../layout/Themes/Theme01/theme";

export const ShortcutsContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    marginTop: 20,
    maxWidth: 1360,
}));

export const ShortcutsContainerMobile = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    width: '90%',
}));

export const ShortcutsGridItem = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "1px solid",
    padding: 10,
    borderRadius: 10,
    cursor: 'pointer',
    marginLeft: 16,
    marginBottom: 16,
    height: 140
}));

export const ShortcutsGridItemMobile = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 8,
    border: "1px solid",
    padding: 1,
    borderRadius: 10,
    cursor: 'pointer',
    marginLeft: -8,
    marginBottom: 16,
}));

export const ShortcutsGridText = styled(Typography)(({ theme }) => ({
    color: Colors.primary,
    fontSize: "1.4rem",
    fontWeight: 600,
    fontFamily: 'Thasadith, sans-serif;'
}));

export const PromotionsContainerMobile = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        padding: "40px 0px 40px 0px",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 200,
    overflow: "hidden",
    background: Colors.secondary,
}));