import styled from 'styled-components';

export const Image = styled.img`
  
  /* &:hover {
    position: absolute;
    margin-left: 25%;
    width: 100px;
    -webkit-transform: scale(3.0);
    transform: scale(3.0);
    z-index: 2;
  } */
`;
