import React from "react";

import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const About: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          marginTop: 16,
        }}
      >
        <Typography
          variant={matches ? "h6" : "h4"}
          component="span"
          style={{ marginBottom: 16 }}
        >
          SOBRE A TENDA OGUM 7 ESPADAS
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            width: "90%",
            gap: 12,
          }}
        >
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>
            Tudo começou com a <strong>D.Angela</strong>, a matriarca, que teve
            seu 1º contato com a Espiritualidade quando ajudava a avó,
            benzedeira.Seu trabalho consistia em buscar ervas e velas para os
            benzimentos, receber a todos e anunciar à avó quem chegava para
            procurá-la.
          </p>
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>
            Já casada com Sr.João e com os filhos crescidos, por intermédio de
            amigos e conhecidos, a família conheceu a{" "}
            <strong>Aldeia da Mata</strong>, que fica na Av.Rio Branco, no
            Centro de São Paulo..
          </p>
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>
            O jovem Jefferson - Jeh, por volta dos 14 anos de idade, passava por
            um problema “sério”: estava prestes a repetir de ano.A mãe insistia
            que ele fosse até a Aldeia para tomar um passe.Quando falou com o
            Caboclo da Mata, foi direcionado ao Caboclo Pena Branca, que lhe
            orientou a parar de conversar e prestar atenção nas aulas.Com essas
            orientações e “puxões de orelha” do Caboclo, Jeh passou de ano e
            ficou encantado.Foi então que começou a frequentar a Aldeia da Mata
            e logo passou a cambonear para as entidades do Sr.João.Só depois de
            quase um ano a D.Angela também começou a trabalhar nesta Casa.
          </p>
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>
            Na Aldeia da Mata de 2001 até 2015, o Pai Jeh aprendeu a limpar a
            casa, receber as pessoas, ficar na lista, fazer cadastros – o Pai
            Elias tem uma instituição que ajuda crianças com câncer e HIV
            chamada Univida, e algumas pessoas se cadastram para fazer
            contribuições mensais ou apadrinhamento das crianças.Foram 14 anos
            entre isqueiros, ervas, organização de viagens, curimbas, aulas e
            muita dedicação até que, após algumas mudanças naturais da vida,
            decidiu se desligar da Casa em dezembro/2015.
          </p>
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>
            Nesta família passaram a ser comuns comentários sobre sentirem falta
            de frequentar uma casa de Umbanda.Durante um aniversário em
            janeiro/2016, uma tia – a Mãe Clarisse Izzo (Nega) – que é Mãe de
            Santo, tinha vindo de Santos para a comemoração e comentou que desde
            o falecimento do seu marido, ocorrido dez anos antes, não havia mais
            tocado e tinha trabalhos para fazer.Essa tia é muito respeitada por
            seu conhecimento e vasta experiência.Então Jefferson, Jessica e
            D.Angela combinaram a data e foram até a praia fazer um trabalho.
          </p>
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>
            Em 20/02/2016 foi feito um trabalho de Exu também na praia.A Mãe
            Clarisse agradeceu pelo auxílio e incentivo e já marcaram um outro
            trabalho, dessa vez de Caboclos.
          </p>
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>
            Ao final desse trabalho, a Mãe Clarisse, toda encabulada, pediu ao
            Jeh que fosse seu Pai Pequeno.Jeh expressou que não tinha certeza,
            mas faria o que pudesse para ajudá-la.
          </p>
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>
            No 3º trabalho na praia, a Espiritualidade confirmou que eles, de
            fato, tinham o compromisso de auxiliar à Mãe Clarisse, mas a missão
            seria muito maior e seria onde moravam.Pediu que iniciassem seu
            trabalho lá (em Barueri).
          </p>
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>
            A Vó Maria Conga e Baiano Zé do Côco pediram que iniciassem os
            Estudos no lar.Desde então, Pai Jefferson, Jessica e D.Angela
            firmaram o compromisso de se reunirem todas as quartas-feiras para
            ler sobre espiritualidade – o que é feito ainda hoje nos Estudos.
          </p>
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>
            Passaram também a visitar terreiros de zeladores que eles conheciam
            ou tinham afinidade, pois além da Aldeia da Mata, o Pai Jeh gostaria
            de conhecer outras casas, ritualísticas e doutrinas.
          </p>
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>
            Ainda como brincadeira, começaram a pensar em nomes para a Casa,
            considerando as afinidades do Pai Jeh com os Baianos e Exu. No dia
            13/06/2016, o Pai Jeh, que não costuma sonhar, teve uma vivência com
            Ogum onde o encontrou, lhe deu um abraço acalentador e disse que, se
            a Casa deve ter um nome, que seja{" "}
            <strong>Tenda de Umbanda Ogum Sete Espadas</strong>. Jeh sabia que
            era filho de Ogum, mas não sabia sobre essa qualidade. A partir daí,
            muitas informações começaram a fazer sentido e a Casa passou a tomar
            forma.
          </p>
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>
            No dia 16/07/2016, os mesmos três (Jefferson, Jessica e D. Angela)
            comentaram que não tinham nenhuma gira pra ir, então a Jessica
            convidou D. Angela para ir até lá, pois a macumba seria feita ali
            mesmo. De banho tomado, D. Angela vestiu branco, Jefferson e Jessica
            arrumaram as imagens do seu altar na estante da sala e então foi
            realizada a gira onde o Pai Jeh recebeu o Caboclo das Sete
            Cachoeiras, que lhe passou diversas orientações sobre o que fazer,
            dias e horários de trabalho, quais seriam as linhas de atendimento,
            além de diversas missões, sendo uma delas a de Cosme e Damião,
            através da distribuição de sacolinhas de doces a crianças carentes
            por ao menos sete anos – que se completaram em 2022.
          </p>
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>
            Toda a estrutura de atendimentos da Casa foi recebida através de
            orientação espiritual. Uma das primeiras orientações dadas pelo
            Caboclo foi que não se preocupassem com quem viria, o que poderiam
            pensar ou dizer; orientou apenas a receber bem as pessoas. Esta não
            seria uma casa de milagres, de curar doentes, de fazer fortunas aos
            assistidos.{" "}
            <strong>
              Seria uma casa de prática de fé e caridade, onde as pessoas viriam
              se entregar ao compromisso com a espiritualidade
            </strong>
            .{" "}
          </p>
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>
            Os assentamentos e tronqueiras da Casa também foram providenciados
            pela Espiritualidade.{" "}
          </p>
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>
            Estamos aqui não uns pelos outros, mas pelo entendimento de que{" "}
            <strong>tudo</strong> acontece a partir do momento que nos
            entregamos e assumimos o compromisso no dia-a-dia, quando buscamos
            entender nossa missão para a evolução espiritual que acontece a cada
            trabalho, ao doar seu tempo, seu corpo (sua matéria), seu
            conhecimento e o conhecimento da sua espiritualidade e ao mesmo
            tempo absorvemos um pouco de tudo isso para nós mesmos.
          </p>
          <p style={{ fontSize: 18, marginBottom: "-16px" }}>Assim seguimos.</p>

          <Typography component="strong">Saravá</Typography>
          <Typography component="strong">Axé</Typography>
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default About;
