/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback } from 'react';

import { Form } from '@unform/web';
import {
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from "@mui/material/styles";

import { Container } from './styles';
import Grid from '../../shared/components/Grid';
import Input from '../../shared/components/Form/Input';
import Checkbox from '../../shared/components/Form/Checkbox';
import Button from '../../shared/components/Form/Button';
import api from '../../service';
import { useNavigate } from 'react-router-dom';
import { useUIContext } from '../../context/ui';

const Login: React.FC = () => {
    const { setIsAuthenticated, setUserData } = useUIContext()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const handleSubmit = useCallback(async (data: any) => {
        console.log('handleSubmit()->data', data);
        const res = await api.post('login', data);

        setIsAuthenticated(true)

        navigate("/admin/estudos", { replace: true });
        console.log('handleSubmit()->res', res);
        setUserData(res.data);

    }, [setIsAuthenticated, navigate, setUserData])

    return (
        <Container>
            <div>
                <img src="/logo_7E.png" width={180} />
            </div>
            <Typography sx={{ ml: 1 }} variant="h6" component="span">
                Acesso
            </Typography>

            <Form
                onSubmit={handleSubmit}
                initialData={{
                    login: 'wilson',
                    password: '123456789'
                }}
                style={{ width: matches ? '80%' : '30%' }}
            >
                <Grid container direction="row" >
                    <Grid item columns={12} >
                        <Input h={50} label='Login' name='login' />
                    </Grid>
                </Grid>

                <Grid container direction="row" >
                    <Grid item columns={12}>
                        <Input h={50} label='Senha' name='password' type={"password"} />
                    </Grid>
                </Grid>

                <Grid container direction="row" spacing={10}>
                    <Checkbox name='remember' label='Lembrar de mim!' />
                </Grid>

                <Grid container direction="row" spacing={12} mTop={20}>
                    <Button fullWidth h={50}>Entrar</Button>
                </Grid>

                <Grid container direction="row" spacing={12} mTop={20}>
                    <p>Esqueceu a senha?</p>
                </Grid>
            </Form>
        </Container>
    );
}

export default Login;