import React from 'react';

import GalleryCard from '../../components/GalleryCard';

const GalleryPhotoDesktop: React.FC = () => {

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%'
        }}>
            <GalleryCard
                title='Festa Nanã'
                datetime='Barueri - 7E, 26 de Julho de 2021'
                text='Nanã é a mais antiga de todos os orixás, surgida com a criação do universo e rainha da lama da qual se origina toda a vida...'
                img='/fotos/nana_2021.jpeg'
                width={580}
                link="/foto/DSA5DA45DA4D64D5AD4SA6D4A"
            />
            <GalleryCard
                title='Festa Nanã'
                datetime='Roça do Caboclo da Mata - 7E, 26 de Julho de 2022'
                text='Nanã é a mais antiga de todos os orixás, surgida com a criação do universo e rainha da lama da qual se origina toda a vida...'
                img='/fotos/nana_2022.jpeg'
                width={580}
                link="/foto/DSA5DA45DA4D64D5AD4SA6D4A"
            />
        </div>
    );
}

export default GalleryPhotoDesktop;