import styled, { css } from 'styled-components';

interface ContainerProps {
    variant?: string;
    fullWidth?: boolean;
    h?: number;
}

export const Container = styled.button<ContainerProps>`
    appearance: button;
    
    background-color: ${({ variant, theme }) =>
        variant === 'primary' && theme.colors.primary ||
        variant === 'error' && theme.colors.error ||
        variant === 'warning' && theme.colors.warning ||
        variant === 'info' && theme.colors.info ||
        variant === 'success' && theme.colors.success ||
        theme.colors.primary
    };
    
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-style: none;
    margin: 0;
    padding: 10px;
    
    text-transform: none;
    transition: color .13s ease-in-out,background .13s ease-in-out,opacity .13s ease-in-out,box-shadow .13s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    width: ${({ fullWidth, theme }) =>
        fullWidth ? '100%' : '150px'
    };

    height: ${({ h, theme }) =>
        h ? `${h}px` : '35px'
    };
    

    span {
        font-size: 20px;
        font-weight: 700;
    }

    &:hover {
        animation: pulsate 1s ease-in-out;
    }

    &:active {
        background-color: ${({ variant, theme }) =>
        variant === 'primary' && theme.colors.primary ||
        variant === 'error' && theme.colors.error ||
        variant === 'warning' && theme.colors.warning ||
        variant === 'info' && theme.colors.info ||
        variant === 'success' && theme.colors.success ||
        theme.colors.primary
    };
    }

    &:disabled {
        background-color: ${({ variant, theme }) =>
        variant === 'primary' && theme.colors.primary ||
        variant === 'error' && theme.colors.error ||
        variant === 'warning' && theme.colors.warning ||
        variant === 'info' && theme.colors.info ||
        variant === 'success' && theme.colors.success ||
        theme.colors.primary
    };
        cursor: no-drop;
    }

    @keyframes pulsate {
        0% {
            box-shadow:
                0 0 10px ${({ variant, theme }) =>
        variant === 'primary' && theme.colors.primary ||
        variant === 'error' && theme.colors.error ||
        variant === 'warning' && theme.colors.warning ||
        variant === 'info' && theme.colors.info ||
        variant === 'success' && theme.colors.success ||
        theme.colors.primary},
                0 0 10px ${({ variant, theme }) =>
        variant === 'primary' && theme.colors.primary ||
        variant === 'error' && theme.colors.error ||
        variant === 'warning' && theme.colors.warning ||
        variant === 'info' && theme.colors.info ||
        variant === 'success' && theme.colors.success ||
        theme.colors.primary};
        }
    }

    .spinner {
        position: relative;
        right: -22px;
        top: 1px;
        width: 4.8px;
        height: 4.8px;
        animation: spinner-z355kx 1s infinite linear;
        border-radius: 4.8px;
        box-shadow: 12px 0px 0 0 ${props => props.theme.colors.white}, 7.4px 9.4px 0 0 ${props => props.theme.colors.white}, -2.6px 11.6px 0 0 ${props => props.theme.colors.white}, -10.8px 5.2px 0 0 ${props => props.theme.colors.white}, -10.8px -5.2px 0 0 ${props => props.theme.colors.white}, -2.6px -11.6px 0 0 ${props => props.theme.colors.white}, 7.4px -9.4px 0 0 ${props => props.theme.colors.white};
    }

    @keyframes spinner-z355kx {
        to {
            transform: rotate(360deg);
        }
    }
`;