import { ReactNode } from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';

import AppBar from '../../../components/AppBar';
import AppBarAdmin from '../../../components/AppBarAdmin';
import theme from './theme'
import { useUIContext } from '../../../context/ui';

function Theme01({ children }: { children: ReactNode; }): JSX.Element {
    const { isLogin, isAdmin } = useUIContext()

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {isLogin === false && isAdmin === false && (<AppBar />)}
                {isAdmin === true && (<AppBarAdmin />)}
                {children}
            </ThemeProvider>

        </>

    );
}

export default Theme01;