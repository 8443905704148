import React from 'react';

import { useNavigate } from "react-router-dom";

import {
    Home as HomeIcon,
    Info as InfoIcon, Map as MapIcon, WhatsApp as PhoneAndroidIcon
} from "@mui/icons-material";

import {
    AppbarContainer,
    AppbarLogo,
    AppbarMenu
} from './style';

import { Button } from '@mui/material';
import { useUIContext } from '../../context/ui';

interface Props {
    matches: boolean;
}

const AppBarDesktop: React.FC<Props> = ({ matches }) => {
    const navigate = useNavigate();
    const { setDrawerOpen } = useUIContext();

    return (
        <AppbarContainer>
            <AppbarLogo onClick={() => navigate('/')}>
                <img src="/logo.png" alt="" width={223} />
            </AppbarLogo>
            <AppbarMenu>
                <Button
                    variant="text"
                    startIcon={<HomeIcon />}
                    onClick={() => navigate("/")}>
                    HOME
                </Button>

                <Button
                    variant="text"

                    startIcon={<InfoIcon />}
                    onClick={() => navigate("/quem-somos")}
                >
                    QUEM SOMOS
                </Button>

                <Button
                    variant="text"

                    startIcon={<MapIcon />}
                    onClick={() => navigate("/localizacao")}
                >
                    LOCALIZAÇÃO
                </Button>

                <Button
                    variant="text"

                    startIcon={<PhoneAndroidIcon />}
                    onClick={() => navigate("/contato")}
                >
                    CONTATO
                </Button>
                {/* 
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => setDrawerOpen(true)}
                >
                    <MenuIcon />
                </IconButton> */}
            </AppbarMenu>
        </AppbarContainer>
    );
}

export default AppBarDesktop;