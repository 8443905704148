import React from 'react';

import ContactDesktop from './ContactDesktop';


const Contact: React.FC = () => {

    return (
        <>
            {/* vamos usar somente a versão desktop, por enquanto */}
            <ContactDesktop />
        </>
    );
}

export default Contact;