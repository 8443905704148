import React from "react";

// import { Container } from './styles';

const Location: React.FC = () => {
  return (
    <>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.0559820308963!2d-46.8679653!3d-23.494493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf03b5f2287021%3A0xe692b678504f9d79!2sR.%20Sol%2C%20282%20-%20Jardim%20Tupanci%2C%20Barueri%20-%20SP%2C%2006414-070!5e0!3m2!1spt-BR!2sbr!4v1678145258230!5m2!1spt-BR!2sbr"></iframe>
    </>
  );
};

export default Location;
