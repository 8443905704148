const theme = {
    colors: {
        background: '#F5F5F5',
        login: {
            background: '#FFFFFF',
            range: '#5482B9',
            slide: '#E7EEFC',
        },
        text: '#A6A5A5',
        input: {
            background: '#EDF0F6',
            label: '#A6A5A5',
        },
        checkbox: {
            border: '#A6A5A5',
        },
        primary: '#032150',
        secondary: '#11cb5f',
        error: '#d32f2f',
        warning: '#ed6c02',
        info: '#0288d1',
        success: '#2e7d32',
        white: '#ffffff',
        gray: '#999',
        tableRow: '#f1f1f1',
        tableHead: '#c68222',
        sidebar: '#ffffff',
    }
}

export default theme