import { createContext, useContext, useState, ReactNode, useEffect } from "react";

interface UserData {
    username: string;
}

interface UIContextData {
    drawerOpen: boolean;
    setDrawerOpen(drawerOpen: boolean): void;
    showSearchBox: boolean;
    setShowSearchBox(showSearchBox: boolean): void;
    iShowMaps: boolean;
    showMaps(iShowMaps: boolean): void;
    isLogin: boolean;
    setIsLogin(isLogin: boolean): void;
    isAdmin: boolean;
    setIsAdmin(isLogin: boolean): void;
    isAuthenticated: boolean;
    setIsAuthenticated(isAuthenticated: boolean): void;
    userData: UserData;
    setUserData(userData: UserData): void;
}


export const UIContext = createContext<UIContextData>({} as UIContextData);
export const useUIContext = () => useContext(UIContext);

function UIProvider({ children }: { children: ReactNode; }): JSX.Element {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [iShowMaps, showMaps] = useState(true);
    const [isLogin, setIsLogin] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(true)
    const [userData, setUserData] = useState<UserData>({} as UserData);

    useEffect(() => {
        console.log('drawerOpen', drawerOpen)

    }, [drawerOpen])


    useEffect(() => {
        console.log('setIsAuthenticated')
        setIsAuthenticated(false);

    }, [setIsAuthenticated])

    const value = {
        drawerOpen,
        setDrawerOpen,
        showSearchBox,
        setShowSearchBox,
        iShowMaps,
        showMaps,
        isLogin,
        setIsLogin,
        isAdmin,
        setIsAdmin,
        isAuthenticated,
        setIsAuthenticated,
        userData,
        setUserData,
    };

    return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}

export default UIProvider;