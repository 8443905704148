import { GridProps } from './index';
import styled, { css } from 'styled-components';

export const Container = styled.div<GridProps>`
    width: ${props => {
        let width = '100%';
        if (props.columns) {
            width = `${Math.round(props.columns * 8.3333)}%`;
        }
        return width
    }};
    
    gap: ${props => props.spacing && `${props.spacing}px`};

    ${props =>
        props.container &&
        css`
        display: flex;
        flex-direction: ${props.direction ? props.direction : 'column'};
    `}

    margin-top: ${({ mTop }) => mTop ? `${mTop}px` : '0px'};
    margin-bottom: 0.5rem;
`;
