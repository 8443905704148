import React from 'react';

import {
    useMediaQuery,
} from '@mui/material';

import { useTheme } from "@mui/material/styles";

import StudiesDesktop from './StudiesDesktop';
import StudiesMobile from './StudiesMobile';

const Studies: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            {matches ? <StudiesMobile /> : <StudiesDesktop />}
        </>
    );
}

export default Studies;
