import { Avatar, Button, List, ListItem, ListItemAvatar, ListItemText, Typography, useMediaQuery } from '@mui/material';
import React, { useCallback } from 'react';
import { useTheme, } from "@mui/material/styles";

const SoursesDesktopShow: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));


    const link = useCallback(() => {
        window.location.href = "https://api.whatsapp.com/send?phone=5511990054606&text=Ol%C3%A1%2C%20gostaria%20de%20fazer%20o%20curso%20de%20Baralho%20Cigano"
    }, [])
    return (
        <div style={{ display: 'flex', justifyContent: 'center', border: '1px solid' }}>
            <div style={{ width: '100%' }}>
                <div>
                    <ListItemAvatar>
                        <Avatar alt="Iluminar by Fe" src="/avatar/felipe_avatar.png" />
                    </ListItemAvatar>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <ListItemText
                            primary="Iluminar by Fe"
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'flex' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Curso de Baralho Cigano - 2
                                    </Typography>
                                    {"Vamos surpriender ainda mais o seu consulente com novas tiragens e formatos."}
                                </React.Fragment>
                            }
                        />
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                <img src="/cursos/baralho.jpeg" alt="Cursos baralho" width={matches ? 320 : 600} style={{ marginLeft: matches ? '-15%' : '-10%', borderRadius: 8 }} />
                                <br />
                                <img src="/cursos/baralho_detalhe.jpeg" alt="Cursos Benzimento" width={matches ? 320 : 600} style={{ marginLeft: matches ? '-15%' : '-10%', borderRadius: 8 }} />
                            </div>
                        </div>

                        <div style={{ marginLeft: matches ? '-15%' : '-10%', width: '100%' }}>
                            <Button variant='contained' color='success' fullWidth onClick={link} >Comprar</Button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default SoursesDesktopShow;