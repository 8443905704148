import { Box, styled } from "@mui/system";
import { Colors } from "../../layout/Themes/Theme01/theme";

export const PromotionsContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    background: Colors.primary,
}));

export const PromotionsContainerMobile = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        padding: "40px 0px 40px 0px",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 200,
    overflow: "hidden",
    background: Colors.secondary,
}));