/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate } from "react-router-dom";

import {
    Grid
} from '@mui/material';

import api from '../../service';
import { ShortcutsContainerMobile, ShortcutsGridItemMobile } from './styles';

interface Images {
    original: string;
    redirect: string;
}

interface Destaques {
    schedule: Images;
    studies: Images;
    courses: Images;
    photos: Images;
}


const ShortcutsMobile: React.FC = () => {
    const [images, setImage] = useState<Destaques>({} as Destaques)
    const navigate = useNavigate();

    const getShorts = useCallback(async () => {
        const { data: schedule } = await api.get('/site/highlight/2')
        const { data: studies } = await api.get('/site/highlight/3')
        const { data: courses } = await api.get('/site/highlight/4')
        const { data: photos } = await api.get('/site/highlight/5')

        console.log('getShorts::schedule', schedule);
        console.log('getShorts::studies', studies);
        console.log('getShorts::courses', courses);
        console.log('getShorts::photos', photos);

        setImage({
            schedule: schedule.galleries,
            studies: studies.galleries,
            courses: courses.galleries,
            photos: photos.galleries,
        })
    }, []);

    useEffect(() => {
        getShorts()
    }, [getShorts])

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ShortcutsContainerMobile>
                <Grid container style={{ display: 'flex', justifyContent: 'space-between', gap: 0, width: '100%' }}>
                    {images.schedule && (
                        <>
                            <ShortcutsGridItemMobile onClick={() => navigate("/agenda")}>
                                <Grid item xs={3} md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={images.schedule.original} style={{ borderRadius: 10, width: 80, height: 40 }} />
                                </Grid>
                            </ShortcutsGridItemMobile>
                        </>
                    )}
                    {images.studies && (
                        <>
                            <ShortcutsGridItemMobile onClick={() => navigate("/estudos")}>
                                <Grid item xs={3} md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={images.studies.original} style={{ borderRadius: 10, width: 80, height: 40 }} />
                                </Grid>
                            </ShortcutsGridItemMobile>
                        </>
                    )}
                    {images.courses && (
                        <>
                            <ShortcutsGridItemMobile onClick={() => navigate("/cursos")}>
                                <Grid item xs={3} md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={images.courses.original} style={{ borderRadius: 10, width: 80, height: 40 }} />
                                </Grid>
                            </ShortcutsGridItemMobile>
                        </>
                    )}
                    {images.photos && (
                        <>
                            <ShortcutsGridItemMobile onClick={() => navigate("/fotos")}>
                                <Grid item xs={3} md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={images.photos.original} style={{ borderRadius: 10, width: 80, height: 40 }} />
                                </Grid>
                            </ShortcutsGridItemMobile>
                        </>
                    )}
                </Grid>
            </ShortcutsContainerMobile>
        </div>
    );
}

export default ShortcutsMobile;