import React from 'react';

import {
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Typography,
    Divider,
    Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';


const SoursesList: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <List sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper' }}>
                <ListItem alignItems='flex-start'>
                    <ListItemAvatar>
                        <Avatar alt="Iluminar by Fe" src="/avatar/felipe_avatar.png" />
                    </ListItemAvatar>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <ListItemText
                            primary="Iluminar by Fe"
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'flex' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Curso de Baralho Cigano - 2
                                    </Typography>
                                    {"Vamos surpriender ainda mais o seu consulente com novas tiragens e formatos."}
                                </React.Fragment>
                            }
                        />
                        <ListItemText>
                            <div style={{ display: 'flex', justifyContent: 'space-between', height: 200 }}>
                                <img src="/cursos/baralho.jpeg" alt="Cursos baralho" width={200} />
                                <img src="/cursos/baralho_detalhe.jpeg" alt="Cursos Benzimento" width={200} />
                            </div>
                        </ListItemText>

                        <ListItemText>
                            <Button variant='contained' fullWidth onClick={() => navigate('/curso/ksdsakldjaksdj')} >Saiba mais</Button>
                        </ListItemText>

                    </div>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems='flex-start'>
                    <ListItemAvatar>
                        <Avatar alt="Wevertton Moraes" src="/avatar/tom_avatar.png" />
                    </ListItemAvatar>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <ListItemText
                            primary="Wevertton Moraes"
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'flex' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Curso de Benzimento - A arte ancestral de abençoar
                                    </Typography>
                                    {"O benzimento é o ato de abençoar, bendize, de tornar bento, herdado pelos nossos ancestrais que ganha força após a colonização do nosso país."}
                                </React.Fragment>
                            }
                        />

                        <ListItemText>
                            <div style={{ display: 'flex', justifyContent: 'space-between', height: 200 }}>
                                <img src="/cursos/benzimento.jpeg" alt="Cursos Benzimento" width={200} />
                                <img src="/cursos/benzimento_detalhe.jpeg" alt="Cursos Benzimento" width={200} />
                            </div>
                        </ListItemText>


                        <ListItemText>
                            <Button variant='contained' fullWidth >Saiba mais</Button>
                        </ListItemText>

                    </div>
                </ListItem>
            </List>
        </>
    );
}

export default SoursesList;