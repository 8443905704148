/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from 'react';
import AppFooterDesktop from './AppFooterDesktop';
import AppFooterMobile from './AppFooterMobile';

const AppFooter: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            {matches ? <AppFooterMobile /> : <AppFooterDesktop />}
        </>
    );
}

export default AppFooter;