import React from 'react';

import { useParams } from "react-router-dom";

import {
    Typography,
    Grid,
    Card,
    CardContent,
    Button,
} from '@mui/material'

import { CardDetails } from '../styles';

const StudiesShow: React.FC = () => {
    const { id } = useParams();
    console.log('id', id);
    return (
        <>
            <Grid container >
                <Grid item key={"asdsadasdsa"} xs={12} md={12}>
                    <Card>
                        <CardDetails>
                            <CardContent>
                                <Button variant="text">
                                    <Typography variant="h5" color="primary">
                                        BATER CABEÇA
                                    </Typography>
                                </Button>
                                <Typography variant="subtitle2" color="textSecondary">
                                    <strong>Estudos do dia: </strong><span>13/06/22</span>
                                </Typography>
                                <br />
                                <img src="/estudos/batercabeca.jpg" alt="" width={'100%'} />
                                <br />
                                <Typography variant="subtitle1" paragraph>
                                    O ato de bater cabeça é a oportunidade que todo o terreiro (médium, ogã, cabone, sacerdote) tem de estabelecer a conexão com os guias e divindades antes mesmo de tomar o passe ou incorporar. O altar é o local do templo onde se encontra a maior concentração energética pura vinda dos assentamentos e das irradiações fluídicas dos Orixás. Ao se prostrar diante do congá, posicionando o Ori na horizontal, estamos não só exercendo um ato ritualístico, como também abrindo o chakra coronário para a entrada dessas irradiações puras.
                                    No ato de bater cabeça, algumas pessoas descrevem sensações como leves tonturas, que são perfeitamente normais e acontecem pelo nível de sensibilidade de cada um, pois ali se entrega o que se tem de mais sagrado: seu próprio Ori a Oxalá e aos seus guias.
                                    O ritual se faz presente em diversas culturas como o Budismo, o Islamismo e até mesmo o Catolicismo sinaliza o ato de reverência e respeito diante do culto, da religião, dos ensinamentos e de tudo o que congrega com aquela crença e tradição.
                                    Na época da escravidão, o culto aos orixás e outros, era proibido. Por isso, o meio utilizado pelos escravos para que não fossem descobertos era enterrar seus Otás (pedra retirada do ponto de força do Orixá) e outros elementos, e assim prosseguir com seu culto de maneira discreta.
                                    Voltando à história por trás do ato em si, é relevante lembrarmos que herdamos dos povos africanos a representação do solo como morada dos antepassados. O elemento terra representa a energia capaz de aliviar as pessoas de cargas negativas Assim, ao bater cabeça com os pensamentos firmados na ação e nas forças divinas, somos capazes de descarregar os pensamentos negativos e atuações negativas que por ventura estejam envolvendo nossas mentes.
                                    A submissão é assunto recorrente quando se fala sobre o ato de bater cabeça, sendo interpretado como o reconhecimento do ser humano diante da onipotência da divindade, ou seja, representa a aceitação de nossas limitações perante aquilo que não podemos controlar, porém diferente da submissão descrita como a obrigação, sujeição e subordinação, aqui tratamos da submissão ligada ao respeito e à entrega que os umbandistas fazem ao seguir a religião.
                                    Quando falamos de um terreiro de Umbanda, devemos lembrar que cada casa tem seu dirigente e sua maneira de conduzir os trabalhos. Por isso, quando se trata de visitantes, é de bom tom reverenciar, ter respeito e conectar-se com aquela força divina, não necessariamente realizando o ato de bater cabeça, que dependendo do local é guardado aos filhos da corrente mediúnica. Por isso, conheça a casa que frequenta e, na dúvida, sempre pergunte ao dirigente como agir.
                                </Typography>
                                <br />
                                <img src="/estudos/batercabeca02.jpg" alt="" width={'100%'} />
                                <br />
                                <br />
                                <div>
                                    <strong>Imagens:</strong><br />
                                    <span>Bater Cabeça - Tenda de Umbanda Luz e Caridade - Tulca</span>
                                    <span>Tambores de Orunmilá: Bater Cabeça na Umbanda Sagrada, história, significado... e por que fazemos isso! - por Mãe Mônica Caraccio - sacerdotisa umbandista (tamboresdeorunmila.blogspot.com)</span>
                                </div>
                                <br />
                                <div>
                                    <strong>Tags:</strong><br />
                                    <span>bater cabeça; otá; terra; reverenciar; ritual; chakra; ori; respeito;</span>
                                </div>
                                <br />
                                <div>
                                    <strong>Fontes:</strong><br />
                                    <span>Bater cabeça na Umbanda o que isso significa? Leia aqui (umbandaead.blog.br)</span><br />
                                    <span>Estudo - O que é Otá? (tucaboclomatavirgem.blogspot.com)</span><br />
                                    <span>*QUATRO ELEMENTOS :: Casa-de-santo-filhos-do-axe (casadesantofilhosdoaxe.com.br)</span><br />
                                    <span>O que significa "Bater Cabeça" na Umbanda - Umbanda 24 Horas</span><br />
                                </div>
                            </CardContent>
                        </CardDetails>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default StudiesShow;