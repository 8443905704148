import React, { useEffect } from 'react';

import {
    useMediaQuery,
} from '@mui/material';
import { useTheme } from "@mui/material/styles";

import { useUIContext } from '../../context/ui';

import GalleryPhotoDesktop from './GalleryPhotoDesktop';
import GalleryPhotoMobile from './GalleryPhotoMobile';

const GalleryPhoto: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const { showMaps } = useUIContext()

    useEffect(() => {
        showMaps(false)
    }, [showMaps])

    return (
        <>
            {matches ? <GalleryPhotoMobile /> : <GalleryPhotoDesktop />}
        </>
    );
}

export default GalleryPhoto;