import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core'

import { Container, CheckboxItem, Label } from './styles';

interface CheckboxProps {
    name: string;
    label?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ name, label }) => {
    const inputRef = useRef(null)
    const { fieldName, defaultValue, registerField, error } = useField(name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                return ref.current.checked
            },
            setValue: (ref, value) => {
                ref.current.checked = value
            },
            clearValue: ref => {
                ref.current.checked = false
            },
        })
    }, [fieldName, registerField])

    return (
        <Container>
            <CheckboxItem>
                <input type="checkbox" name={name} ref={inputRef} defaultValue={defaultValue} />
                <span />
            </CheckboxItem>
            <Label htmlFor={label} >{label}</Label>
        </Container>
    );
}

export default Checkbox;