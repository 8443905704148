import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";


export const DrawerCloseButton = styled(IconButton)(() => ({
    position: 'absolute',
    top: 10,
    right: 250,
    zIndex: 1999,
}));

export const DrawerCloseButtonMobile = styled(IconButton)(() => ({
    position: 'absolute',
    top: 10,
    left: 250,
    zIndex: 1999,
}));