import React from 'react';

import Carousel from '../../components/Carousel';

import SoursesList from '../../components/SoursesList';

const SoursesDesktop: React.FC = () => {


    return (
        <>
            <Carousel />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <SoursesList />
            </div>
        </>
    );
}

export default SoursesDesktop;