/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  WebAsset as WebAssetIcon,
  YouTube as YouTubeIcon,
} from "@mui/icons-material";

import {
  CompanyAddress,
  CompanyAddressMobileText,
  CompanyCopyRight,
  CompanyCopyRightMobileText,
  CompanyMessage,
  CompanyMessageText,
  CompanyName,
  CompanyNameMobileText,
  CompanySocialNetwork,
  CompanySocialNetworkTitle,
  Container,
  ContainerLeft,
  DevelopCompanyMobile,
} from "./styles";

const AppFooterMobile: React.FC = () => {
  return (
    <>
      <Container>
        <ContainerLeft>
          <CompanyName>
            <CompanyNameMobileText>
              Tenda de Umbanda Ogum 7 Espadas
            </CompanyNameMobileText>
          </CompanyName>

          <CompanyAddress>
            <CompanyAddressMobileText>
              Rua Sol, 282 B - Jardim Tupanci - Barueri - SP - CEP: 06414-070
            </CompanyAddressMobileText>
          </CompanyAddress>

          <CompanySocialNetwork>
            <a
              href="http://tendaogum7espadas.com.br"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#FFF", marginRight: 5 }}
            >
              <WebAssetIcon />
            </a>
            <a
              href="https://www.facebook.com/tendaogum7espadas"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#FFF", marginRight: 5 }}
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.instagram.com/tendaogum7espadas"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#FFF", marginRight: 5 }}
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.youtube.com/@tendaogum7espadas853"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#FFF", marginRight: 5 }}
            >
              <YouTubeIcon />
            </a>
            <CompanySocialNetworkTitle>
              TENDAOGUM7ESPADAS
            </CompanySocialNetworkTitle>
          </CompanySocialNetwork>

          <CompanyMessage>
            <CompanyMessageText>
              Umbanda – Fé, amor, caridade,
            </CompanyMessageText>
            <CompanyMessageText>
              trabalho, estudo e esforço na prática do bem!
            </CompanyMessageText>
            <CompanyMessageText>
              "Com quem sabe mais, aprenderemos. A quem sabe menos, ensinaremos"
            </CompanyMessageText>
            <CompanyMessageText>Caboclo 7 Encruzilhadas</CompanyMessageText>
          </CompanyMessage>

          <CompanyCopyRight>
            <CompanyCopyRightMobileText>
              Copyright 2023 - Tenda de Umbanda Ogum 7 Espadas
            </CompanyCopyRightMobileText>
          </CompanyCopyRight>

          <DevelopCompanyMobile>
            <a
              href="https://provehito.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Desenvolvido por Provehito Sistemas</span>
            </a>
          </DevelopCompanyMobile>
        </ContainerLeft>
      </Container>
    </>
  );
};

export default AppFooterMobile;
