import React from 'react';

// import { Container } from './styles';

const AdminAgenda: React.FC = () => {
    return (
        <>
            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: '90%', alignItems: 'flex-start', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column' }}>
                    <h1>Agenda</h1>
                </div>
            </div>
        </>
    );
}

export default AdminAgenda;