import React from 'react';
import NumerologyDesktop from './NumerologyDesktop';

const Numerology: React.FC = () => {

    return (
        <>
            <NumerologyDesktop />
        </>
    );
}

export default Numerology;