import React from "react";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Menu: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      style={{
        borderRadius: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        backgroundColor: "#182750",
        padding: matches ? 0 : 50,
      }}
    >
      <img
        src="/menu/feijoada.jpeg"
        style={{
          borderRadius: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: matches ? "100%" : "30%",
          height: matches ? undefined : "100%",
        }}
      />
    </div>
  );
};

export default Menu;
