import React, { useCallback, useEffect } from 'react';

import {
    useMediaQuery,
} from '@mui/material';
import { useTheme } from "@mui/material/styles";

import ImageGallery from 'react-image-gallery';

import { Card, CardHeader, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom';

const images = [
    {
        original: '/fotos/nana_2021.jpeg',
        thumbnail: '/fotos/nana_2021.jpeg',
    },
    {
        original: '/fotos/nana_2021_02.jpeg',
        thumbnail: '/fotos/nana_2021_02.jpeg',
    },
    {
        original: '/fotos/nana_2021_03.jpeg',
        thumbnail: '/fotos/nana_2021_03.jpeg',
    },
    {
        original: '/fotos/nana_2021_04.jpeg',
        thumbnail: '/fotos/nana_2021_04.jpeg',
    },
    {
        original: '/fotos/nana_2021_05.jpeg',
        thumbnail: '/fotos/nana_2021_05.jpeg',
    },
    {
        original: '/fotos/nana_2021_06.jpeg',
        thumbnail: '/fotos/nana_2021_06.jpeg',
    }
];

const GalleryPhotoShow: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate();
    const handlerNavigate = useCallback(() => {
        navigate("/fotos")
    }, [navigate])

    useEffect(() => {
        window.scrollTo({ top: 105, behavior: 'smooth' });
    }, [])

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginBottom: 18

        }}>
            <Card sx={{ maxWidth: matches ? '90%' : '27%', marginTop: 4 }}>
                <CardHeader
                    title={"Festa Nanã"}
                    subheader={"Barueri - 7E, 26 de Julho de 2021"}
                    action={
                        <IconButton aria-label="close" onClick={handlerNavigate}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <ImageGallery
                    showPlayButton={false}
                    items={images}
                />
            </Card>
        </div>

    );
}

export default GalleryPhotoShow;