import React from 'react';

import {
    Menu as MenuIcon,
    Search as SearchIcon,
} from "@mui/icons-material";

import {
    AppbarContainer,
    AppbarLogo,
    AppbarMenuMobile,
} from './style'

import { IconButton } from '@mui/material';
import { useUIContext } from '../../context/ui';

interface Props {
    matches: boolean;
}

const AppBarAdminMobile: React.FC<Props> = ({ matches }) => {
    const { setDrawerOpen } = useUIContext();

    return (
        <AppbarContainer>
            <AppbarMenuMobile>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => setDrawerOpen(true)}
                >
                    <MenuIcon />
                </IconButton>
                <AppbarLogo>
                    <img src="/logo_7E.png" alt="" width={80} />
                </AppbarLogo>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                >
                    <SearchIcon />
                </IconButton>
            </AppbarMenuMobile>
        </AppbarContainer >
    );
}

export default AppBarAdminMobile;