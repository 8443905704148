import {
    useMediaQuery
} from '@mui/material';
import { useTheme } from "@mui/material/styles";
import React from 'react';
import ScheduleDesktop from './ScheduleDesktop';
import ScheduleMobile from './ScheduleMobile';

const Schedule: React.FC = () => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            {matches ? <ScheduleMobile /> : <ScheduleDesktop />}
        </>
    );
}

export default Schedule;
