import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import React from 'react';
import AppBarAdminDesktop from './AppBarAdminDesktop';
import AppBarAdminMobile from './AppBarAdminMobile';

const AppBarAdmin: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            {matches ? <AppBarAdminMobile matches={matches} /> : <AppBarAdminDesktop matches={matches} />}
        </>
    );
}

export default AppBarAdmin;
