import React from 'react';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import ShortcutsDesktop from './ShortcutsDesktop';
import ShortcutsMobile from './ShortcutsMobile';

const Shortcuts: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            {matches ? <ShortcutsMobile /> : <ShortcutsDesktop />}
        </>
    );
}

export default Shortcuts;