import "react-image-gallery/styles/css/image-gallery.css";
import React from "react";
import { useMatch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "./styles/theme";

import { CssBaseline, Stack } from "@mui/material";

import Master from "./layout";
import UIProvider from "./context/ui";
import Routes from "./routes";
import { log } from "console";

const App: React.FC = () => {
  const cardapio = useMatch("/cardapio");

  console.log("cardapio::", cardapio);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UIProvider>
          {cardapio === null ? (
            <Master>
              <Stack>
                <Routes />
              </Stack>
            </Master>
          ) : (
            <Stack>
              <Routes />
            </Stack>
          )}
        </UIProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
