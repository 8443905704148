import React from 'react';

import { Container } from './styles';

interface ButtonProps {
    children: React.ReactNode;
    loading?: boolean;
    variant?: 'primary' | 'error' | 'warning' | 'info' | 'success';
    fullWidth?: boolean;
    h?: number;
}

const Button: React.FC<ButtonProps> = (
    {
        children,
        loading,
        variant,
        fullWidth,
        h
    }) => {
    return (
        <>
            <Container
                disabled={loading}
                variant={variant}
                fullWidth={fullWidth}
                h={h}
            >
                <span>{children}</span>
                {loading && <div className="spinner" />}
            </Container>
        </>
    );
}

export default Button;