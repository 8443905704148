import React, { useCallback, useState } from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ShareIcon from '@mui/icons-material/Share';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { useNavigate } from 'react-router-dom';
import { Badge } from '@mui/material';

interface GalleryCardProps {
    title?: string;
    datetime?: string;
    img?: string;
    text?: string;
    width?: number;
    link?: string;
}

const GalleryCard: React.FC<GalleryCardProps> = ({ title, datetime, img, text, width, link }) => {
    const [likes, setLikes] = useState(0)
    const navigate = useNavigate();
    const handlerNavigate = useCallback((link: string | undefined) => {
        if (link)
            navigate(link)
    }, [navigate])


    const openInNewTab = useCallback((url: string | undefined) => {
        if (url)
            window.open(`whatsapp://send?text=${title} https://demo.tendaogum7espadas.com.br${url}`, '_blank', 'noopener,noreferrer');
    }, []);

    return (
        <Card sx={{ maxWidth: width, marginTop: 4 }}>
            <div onClick={() => handlerNavigate(link)}>
                <CardHeader
                    title={title}
                    subheader={datetime}
                />
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CardMedia
                        component="img"
                        image={img && img}
                        alt={title}
                        style={{ width: '75%' }}
                    />
                </div>

                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {text}
                    </Typography>
                </CardContent>
            </div>
            <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <IconButton aria-label="share" onClick={() => setLikes(likes + 1)}>
                    <Badge
                        aria-label="add to like"
                        badgeContent={likes}
                        color="success"
                    >
                        <ThumbUpOffAltIcon />
                    </Badge>
                </IconButton>
                <IconButton aria-label="share" onClick={() => openInNewTab(link)}>
                    <ShareIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
}

export default GalleryCard;