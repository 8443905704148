import React from 'react';

import {
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Typography,
    Divider,
    Button,
    Box,
    Modal,
} from '@mui/material';

import { Image } from './styles';
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    p: 4,
};


const SoursesMobile: React.FC = () => {
    const [open, setOpen] = React.useState(false);
    const [imagemSel, setImagemSel] = React.useState("");
    const navigate = useNavigate();

    const handleOpen = (imagem: string) => {
        setImagemSel(imagem);
        setOpen(true);
    }
    const handleClose = () => setOpen(false);


    return (
        <>
            <List sx={{ width: '100%', maxWidth: 350, bgcolor: 'background.paper' }}>
                <ListItem alignItems='flex-start'>
                    <ListItemAvatar>
                        <Avatar alt="Iluminar by Fe" src="/avatar/felipe_avatar.png" />
                    </ListItemAvatar>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <ListItemText
                            primary="Iluminar by Fe"
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'flex' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Curso de Baralho Cigano - 2
                                    </Typography>
                                    {"Vamos surpriender ainda mais o seu consulente com novas tiragens e formatos."}
                                </React.Fragment>
                            }
                        />
                        <ListItemText>
                            <div style={{ display: 'flex', justifyContent: 'space-between', height: 120 }}>
                                <Image src="/cursos/baralho.jpeg" alt="Cursos baralho" width={120} onClick={() => handleOpen("/cursos/baralho.jpeg")} />
                                <Image src="/cursos/baralho_detalhe.jpeg" alt="Cursos Benzimento" width={120} onClick={() => handleOpen("/cursos/baralho_detalhe.jpeg")} />
                            </div>
                        </ListItemText>

                        <ListItemText>
                            <Button variant='contained' fullWidth onClick={() => navigate('/curso/ksdsakldjaksdj')}>Saiba mais</Button>
                        </ListItemText>

                    </div>
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems='flex-start'>
                    <ListItemAvatar>
                        <Avatar alt="Wevertton Moraes" src="/avatar/tom_avatar.png" />
                    </ListItemAvatar>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <ListItemText
                            primary="Wevertton Moraes"
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'flex' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Curso de Benzimento - A arte ancestral de abençoar
                                    </Typography>
                                    {"O benzimento é o ato de abençoar, bendize, de tornar bento, herdado pelos nossos ancestrais que ganha força após a colonização do nosso país."}
                                </React.Fragment>
                            }
                        />

                        <ListItemText>
                            <div style={{ display: 'flex', justifyContent: 'space-between', height: 120 }}>
                                <Image src="/cursos/benzimento.jpeg" alt="Cursos Benzimento" width={120} onClick={() => handleOpen("/cursos/benzimento.jpeg")} />
                                <Image src="/cursos/benzimento_detalhe.jpeg" alt="Cursos Benzimento" width={120} onClick={() => handleOpen("/cursos/benzimento_detalhe.jpeg")} />
                            </div>
                        </ListItemText>


                        <ListItemText>
                            <Button variant='contained' fullWidth onClick={() => navigate('/curso/ksdsakldjaksdj')}>Saiba mais</Button>
                        </ListItemText>

                    </div>
                </ListItem>
            </List>

            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} onClick={() => setOpen(false)} >
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} >
                            <Image src={imagemSel} alt="Cursos baralho" width={300} />
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    );
}

export default SoursesMobile;