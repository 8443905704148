import React, { useCallback, useState } from "react";

import {
  Alert,
  Button,
  CircularProgress,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import api from "../../service";
import {
  ContainerForm,
  ContainerFormItens,
  ContainerLoading,
  ContainerMessageSuccess,
} from "./styles";


const NumerologyDataFilosofia = [
  `Abre caminhos pela força.`,
  `Une pela flexibilidade.`,
  `Expande pelo crescimento.`,
  `Estabiliza pela estrutura.`,
  `Transforma pelas mudanças.`,
  `Ama pela afetividade.`,
  `Autoconhece pela introspecção`,
  `É justo pela retidão (é 8 ou 80)`,
  `É universal pela doação.`
];

const NumerologyData = [
  `Início do ciclo. Ano para arar a terra e plantar
  sementes. Neste ano é bom iniciar coisas novas em todas as
  áreas de sua vida. Mas procure começar bem, porque os frutos
  de suas ações neste ano irão influenciar por todo o ciclo que se
  encerrará no ano pessoal de número 9. É ano para iniciar novas
  empreitadas, mudar suas decisões, iniciar novas atividades,
  reformular as coisas, modificando desde suas bases. É um ano de
  coisas novas, de plantar, esperando para colher mais adiante.`,
  `Ano de regar e cuidar da semente que se
  plantou no passado. Mas, continua sendo necessário ter atitudes
  responsáveis. Período de parceria, paciência, diplomacia. É
  melhor cumprir os compromissos e estar atento a detalhes do
  que impor suas novas ideias a todo custo. Mantenha a tolerância
  nas situações sociais e nas demoras que podem acontecer nesse
  período. Procure conter a agressividade e aceitar a vida numa
  posição receptiva. Propício também para se casar.`,
  `Ano de descontração e alegria, de começar a
  colher os seus frutos. Procure espalhar o bom humor em todas
  as áreas da vida. Bom para a imaginação e a criatividade.
  Favorável à vida social, ao lazer e às festas. Deve ter em mente
  seus objetivos e não se dispersar. Pode iniciar algum projeto
  novo, pois tende a crescer. A expressão artística é muito bem-
  vinda, assim como as amizades, as conversas, as trocas.`,
  `Ano de bastante trabalho e dedicação aos
  empreendimentos materiais. Propício a poupar dinheiro e a
  construir bases sólidas para o futuro. É preciso paciência e
  organização para lidar com as questões práticas e sólidas. Bom
  para cuidar da casa e cultivar os relacionamentos estáveis.`,
  `Ano bom para viajar, fazer mudanças e contatos
  sociais, conhecer outras culturas, ir em busca de algo novo.
  Liberdade é essencial nesse período. Procure estar aberto ao
  novo e não planejar demais, pois é um tempo cheio de
  imprevistos e novidades, podendo até causar altos e baixos
  emocionais. Não mude simplesmente por mudar...`,
  `Ano propício ao lar, à família, ao casamento e
  aos amigos. Bom para decorar o ambiente, para atividades
  artísticas e em grupos. Tempo de dar apoio aos familiares e
  amigos, cuidar da comunidade. Buscar o conforto e as terapias
  ou atividades de cura. Bom para apreciar a beleza.
  Responsabilidades e afetos.`,
  `Período de interiorização. Pouca produtividade
  material. É um tempo de entrar na sua própria casquinha e lá
  ficar, meditando, avaliando, fazendo um balanço dos períodos
  anteriores. Tempo bom para os estudos e assuntos espirituais.
  Necessidade de privacidade. Encontro com as forças internas e
  superiores, com o que não é aparente.`,
  `Bens e dinheiro serão a tônica do ano. Aja de
  modo otimista e prudente para poder aproveitar ao máximo este
  momento. É tempo de colher (ou não) o que foi semeado e
  cultivado desde o Ano Pessoal 1. Cuidado com o excesso de
  materialismo. Período de projeção, sucesso, ganhos financeiros,
  empreendimentos. Pague o que deve neste ano.`,
  `Fim de ciclo. É um ano para encerrar, e não para
  iniciar algo, seja projeto ou relacionamento. Faça uma avaliação
  e elimine tudo o que considerar inútil em sua vida. Poderão
  acontecer perdas em finanças ou em amizades; por isso, muito
  cuidado com o que vender, comprar ou ao assinar contratos.
  Período de compartilhar conhecimentos e de adquiri-los
  também, de ajudar os outros, de dar à humanidade a sabedoria
  que acumulou nos anos anteriores. Propício à expansão da
  consciência.`
];

const NumerologyDesktop: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false);
  const [messageSend, setMessageSend] = useState<string>(``);
  const [dataNascimento, setDataNascimento] = useState<string>('');
  const [anoPessoal, setAnoPessoal] = useState<number>(0);

  const formatarData = (ano: number, mes: number, dia: number) => {
    // Função auxiliar para garantir que os números tenham dois dígitos
    const padZero = (num: number) => num.toString().padStart(2, '0');

    return `${padZero(dia)}/${padZero(mes)}/${ano}`;
  };

  const calcularAnoPessoal = (dataNascimentoPrm: string) => {
    console.log("calcularAnoPessoal()");
    // if (!dataNascimentoPrm || !/^\d{4}-\d{2}-\d{2}$/.test(dataNascimentoPrm)) {
    //   alert('Por favor, insira uma data de nascimento válida (AAAA-MM-DD).');
    //   return;
    // }

    const [dia, mes, ano] = dataNascimentoPrm.split('/');

    const digitosDia = dia.toString().split('').map(Number);
    const digitosMes = mes.toString().split('').map(Number);
    console.log('aaaa', digitosDia, digitosMes);

    let diaTotal = 0;
    let mesTotal = 0;
    let anoPessoalTotal = 0;

    if (digitosDia[0] >= 0 && digitosDia[1] >= 0) {
      diaTotal = digitosDia[0] + digitosDia[1];
    }

    console.log('diaTotal', diaTotal);
    if (digitosMes[0] >= 0 && digitosMes[1] >= 0) {
      mesTotal = digitosMes[0] + digitosMes[1];
    }
    console.log('mesTotal', mesTotal);

    let anoPessoalPre = diaTotal + mesTotal + 8
    console.log('anoPessoalPre', anoPessoalPre);

    if (anoPessoalPre.toString().length == 2) {
      const digitosAnoPessoal = anoPessoalPre.toString().split('').map(Number);
      console.log('digitosAnoPessoal', digitosAnoPessoal);

      if (digitosAnoPessoal[0] >= 0 && digitosAnoPessoal[1] >= 0) {
        anoPessoalTotal = digitosAnoPessoal[0] + digitosAnoPessoal[1];
      }

      console.log('anoPessoalTotal', anoPessoalTotal);

      if (anoPessoalTotal.toString().length == 2) {
        console.log('anoPessoalTotal length', anoPessoalTotal);
        const digitosAnoPessoalPos = anoPessoalTotal.toString().split('').map(Number);
        if (digitosAnoPessoalPos[0] >= 0 && digitosAnoPessoalPos[1] >= 0) {
          anoPessoalTotal = digitosAnoPessoalPos[0] + digitosAnoPessoalPos[1];
        }
      }

    }

    console.log('calcularAnoPessoal() Rsl anoPessoalTotal ', anoPessoalTotal - 1);
    
    return anoPessoalTotal - 1;

  };

  const { register, handleSubmit } = useForm();


  const handleCreateNumerology = useCallback(async (dataForm: any) => {
    setLoading(true);
    console.log("handleCreateNumerology()->dataForm::", dataForm);

    if (dataForm) {
      let anoPessoaRsl = calcularAnoPessoal(dataForm.birth_date);
      setAnoPessoal(anoPessoaRsl);
      setMessageSend(NumerologyData[Number(anoPessoaRsl)]);

    }


    setLoading(false);
  }, [NumerologyData]);

  const handleBackNumerology = useCallback(async () => {
    setMessageSend("");
  }, []);


  return (
    <div
      style={{
        display: "flex",
        justifyItems: "center",
        alignContent: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          marginTop: 16,
        }}
      >
        <Typography
          variant={matches ? "h6" : "h4"}
          component="span"
          style={{ marginLeft: matches ? "-5px" : 20 }}
        >
          Mapa Numerologia
        </Typography>

      </div>

      {messageSend && anoPessoal >= 0 ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
         
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '50%' }}>
              <hr style={{ height: 0, border: '1px solid #ccc', width: '100%' }} />
              <h2 style={{ marginBottom: '0' }}>Lição de vida</h2>
              <span>Seu numéro é: <b>1</b></span>
              <Alert variant="standard" severity="info" icon={false}>
                Aprender a ser independente, agindo com
                coragem e liderança.
              </Alert>
            </div>
            <br /> */}

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '50%' }}>
              <hr style={{ height: 0, border: '1px solid #ccc', width: '100%' }} />
              <h2 style={{ marginBottom: '0' }}>Ano pessoal</h2>
              <span>Seu numéro será o: <b>{anoPessoal + 1}</b></span>
              <Alert variant="standard" severity="success"  icon={false}>
                {messageSend}
              </Alert>
            </div>
            <br />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '50%' }}>
              <hr style={{ height: 0, border: '1px solid #ccc', width: '100%' }} />
              <h2 style={{ marginBottom: '0' }}>Filosofia numerológica</h2>
              <Alert variant="standard" severity="warning" icon={false}>
                {NumerologyDataFilosofia[anoPessoal]}
              </Alert>
            </div>
            <br />

            <form onSubmit={handleSubmit(handleBackNumerology)}>
              <Button
                fullWidth
                style={{
                  width: '300px'
                }}
                variant="contained"
                type="submit"
              >
                Novo Mapa
              </Button>
            </form>
            <br />
          </div>
        </>
      ) : (
        <>
          {loading ? (
            <ContainerLoading>
              <CircularProgress size={22} color="success" />
              <Alert variant="standard" severity="info">
                Enviando sua mensagem...
              </Alert>
            </ContainerLoading>
          ) : (
            <>
              <form onSubmit={handleSubmit(handleCreateNumerology)}>
                <div
                  style={{
                    display: "flex",
                    justifyItems: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    marginTop: 8,
                  }}
                >
                  <ContainerForm>
                    <Typography
                      sx={{ ml: 1 }}
                      variant="h6"
                      component="span"
                      style={{ marginLeft: "-50px" }}
                    >
                      Dados de Aniversário
                    </Typography>
                    <ContainerFormItens>
                      <TextField
                        fullWidth
                        label="Nome"
                        placeholder="Nome"
                        variant="outlined"
                        required
                        InputLabelProps={{ shrink: true }}
                        {...register("name")}
                      />
                      <TextField
                        fullWidth
                        label="Data Nascimento"
                        placeholder="99/99/9999"
                        variant="outlined"
                        required
                        InputLabelProps={{ shrink: true }}
                        {...register("birth_date")}
                      />
                      <Button
                        fullWidth
                        style={{
                          display: "flex",
                          justifyItems: "center",
                          alignContent: "center",
                        }}
                        variant="contained"
                        type="submit"
                      >
                        Enviar
                      </Button>
                    </ContainerFormItens>
                  </ContainerForm>
                </div>
              </form>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default NumerologyDesktop;
