import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import React from 'react';
import AppBarDesktop from './AppBarDesktop';
import AppBarMobile from './AppBarMobile';

const AppBar: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            {matches ? <AppBarMobile matches={matches} /> : <AppBarDesktop matches={matches} />}
        </>
    );
}

export default AppBar;
