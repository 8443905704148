import styled from 'styled-components';

interface ContainerProps {
    h?: number;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    width: 100%;

    input {
        width: 100%;
        min-height: 2rem;
        height: ${({ h, theme }) => h ? `${h}px` : '2rem'};
        border-radius: 4px;
        border: 2px solid transparent;
        padding: 0.6rem;
        margin-top: 5px;
        background-color: ${props => props.theme.colors.input.background};
        

        &:active, :focus {
            outline: none;
            border: 2px solid ${props => props.theme.colors.secondary};
        }
    }

    .spinner {
        position: relative;
        right: 25px;
        top: 3px;
        width: 4.8px;
        height: 4.8px;
        animation: spinner-z355kx 1s infinite linear;
        border-radius: 4.8px;
        box-shadow: 12px 0px 0 0 ${props => props.theme.colors.primary}, 7.4px 9.4px 0 0 ${props => props.theme.colors.primary}, -2.6px 11.6px 0 0 ${props => props.theme.colors.primary}, -10.8px 5.2px 0 0 ${props => props.theme.colors.primary}, -10.8px -5.2px 0 0 ${props => props.theme.colors.primary}, -2.6px -11.6px 0 0 ${props => props.theme.colors.primary}, 7.4px -9.4px 0 0 ${props => props.theme.colors.primary};
    }

    @keyframes spinner-z355kx {
        to {
            transform: rotate(360deg);
        }
    }
`;

export const Label = styled.label`
    color: ${props => props.theme.colors.input.label};
    font-weight: 500;
`
