import styled from 'styled-components';

export const Container = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 400px;

    background-color: #032150;
    font-family: 'Thasadith', sans-serif;
    color: red;
`;

export const ContainerLeft = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    width: 80%;
    height: 100%;

    padding: 20px 0px 0px 10px;
`;

export const CompanyName = styled.div``;

export const CompanyNameText = styled.span`
    font-size: 20px;
    color: #ffffff;
    font-weight: bold;
`;

export const CompanyNameMobileText = styled.span`
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;
`;

export const CompanyAddress = styled.div``;

export const CompanyAddressText = styled.span`
    font-size: 20px;
    color: #ffffff;
    font-weight: bold;
`;

export const CompanyAddressMobileText = styled.span`
    font-size: 12px;
    color: #ffffff;
    font-weight: bold;
`;

export const CompanySocialNetwork = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
`;

export const CompanySocialNetworkTitle = styled.span`
    font-size: 14px;
    color: #ffffff;
    font-weight: 700;
`;

export const CompanyMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 50px;
`;

export const CompanyMessageText = styled.span`
    font-size: 12px;
    color: #ffffff;
    font-weight: bold;
    text-align: left;
`;

export const CompanyCopyRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 20px;

    width: 100%;
`;

export const CompanyCopyRightText = styled.span`
    font-size: 12px;
    color: #ffffff;
    font-weight: bold;
    text-align: left;
`;

export const CompanyCopyRightMobileText = styled.span`
    font-size: 9px;
    color: #ffffff;
    font-weight: bold;
    text-align: left;
`;

export const DevelopCompany = styled.div`
    margin-bottom: 20px;

    a {
        text-decoration: none;
    }

    span {
        font-size: 15px;
        color: #ffffff;
        font-weight: bold;
        text-align: left;
    }
`;

export const DevelopCompanyMobile = styled.div`
    margin-bottom: 20px;

    a {
        text-decoration: none;
    }

    span {
        font-size: 9px;
        color: #ffffff;
        font-weight: bold;
        text-align: left;
    }
`;

export const ContainerRight = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    width: 20%;
    height: 100%;
`;


export const ContainerRightLinks = styled.div`
    margin-top: 40px;
`;

export const LinksFooter = styled.ul`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
`;
export const LinksFooterItem = styled.li`
    color: #fff !important;
    font-weight: bold !important;
    list-style: none;

    padding: 4px;
`;

export const LinksFooterItemLink = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff !important;
    font-weight: bold !important;

    cursor: pointer;

    svg {
        margin-right: 16px;
    }
`;