import React from 'react';

import { Container } from './styles';

export interface GridProps {
    children: React.ReactNode;
    columns?: 1 | 1.5 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    container?: boolean | false;
    item?: boolean | false;
    direction?: 'column-reverse' | 'column' | 'row-reverse' | 'row';
    spacing?: number;
    mTop?: number;
}

const Grid: React.FC<GridProps> = ({
    children,
    container,
    item,
    columns,
    direction,
    spacing,
    mTop,
}) => {

    return (
        <>
            <Container
                columns={columns}
                container={container}
                direction={direction}
                item={item}
                spacing={spacing}
                mTop={mTop}
            >
                {children}
            </Container>
        </>
    );
}

export default Grid;