import React from 'react';

import {
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from "@mui/material/styles";
import SoursesMobile from './SoursesMobile';
import SoursesDesktop from './SoursesDesktop';


const Sourses: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                    sx={{ display: 'flex' }}
                    component="span"
                    variant="h5"
                    color="text.primary"
                >
                    Cursos 7E
                </Typography>
            </div>
            {matches ? <SoursesMobile /> : <SoursesDesktop />}
        </>
    );
}

export default Sourses;