import React, { InputHTMLAttributes, useRef, useEffect } from 'react';
import { useField } from '@unform/core'
import InputMask from 'react-input-mask';

import { Container, Label } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    icon?: React.ReactNode;
    mask?: string | Array<(string | RegExp)>;
    loading?: boolean;
    h?: number;
}

const Input: React.FC<InputProps> = ({
    name,
    label,
    icon,
    mask,
    loading,
    h,
    ...rest }) => {
    const inputRef = useRef(null)

    const { fieldName, defaultValue, registerField, error } = useField(name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                return ref.current.value
            },
            setValue: (ref, value) => {
                ref.current.value = value
            },
            clearValue: ref => {
                ref.current.value = ''
            },
        })
    }, [fieldName, registerField])

    return (
        <Container h={h}>
            {label && <Label htmlFor={label} >{label}</Label>}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                {icon && (
                    <div style={{ marginRight: 8 }}>
                        {icon}
                    </div>
                )}

                {mask ? (
                    <InputMask mask={mask} name={name} ref={inputRef} defaultValue={defaultValue} placeholder={mask.toString()} {...rest} />
                ) : (
                    <input name={name} placeholder={label} ref={inputRef} defaultValue={defaultValue} {...rest} />
                )}

                {loading && <div className="spinner" />}

                {error && <span className="error">{error}</span>}
            </div>
        </Container >
    );
}

export default Input;