import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { useUIContext } from "../../context/ui";

import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import {
  CalendarMonth as CalendarIcon,
  Close as CloseIcon,
  Home as HomeIcon,
  Info as InfoIcon,
  LocationOn as LocationOnIcon,
  PhoneAndroid as PhoneAndroidIcon,
} from "@mui/icons-material";

import { Colors } from "../../layout/Themes/Theme01/theme";
import { DrawerCloseButton, DrawerCloseButtonMobile } from "./styles";

const AppDrawer: React.FC = () => {
  const navigate = useNavigate();
  const { drawerOpen, setDrawerOpen } = useUIContext();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const handlerNavigate = useCallback(
    (link: string) => {
      setDrawerOpen(false);
      navigate(link);
    },
    [navigate, setDrawerOpen]
  );

  return (
    <>
      {drawerOpen && (
        <>
          {!matches ? (
            <DrawerCloseButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon
                sx={{
                  fontSize: "2.5rem",
                  color: Colors.secondary,
                }}
              />
            </DrawerCloseButton>
          ) : (
            <DrawerCloseButtonMobile onClick={() => setDrawerOpen(false)}>
              <CloseIcon
                sx={{
                  fontSize: "2.5rem",
                  color: Colors.secondary,
                }}
              />
            </DrawerCloseButtonMobile>
          )}
        </>
      )}
      <Drawer
        open={drawerOpen}
        anchor={matches ? "left" : "right"}
        style={{ width: 220 }}
      >
        <Box sx={{ width: 300, maxWidth: 460, bgcolor: "background.paper" }}>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 16 }}
          >
            <img
              src="/logo.png"
              style={{ borderRadius: 10 }}
              width={210}
              alt="logo"
            />
          </div>
          <List component="nav" aria-label="main mailbox folders">
            {matches ? (
              <>
                <ListItemButton onClick={() => handlerNavigate("/")}>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="HOME" color="primary" />
                </ListItemButton>

                <ListItemButton onClick={() => handlerNavigate("/quem-somos")}>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText primary="QUEM SOMOS" />
                </ListItemButton>

                <ListItemButton onClick={() => handlerNavigate("/agenda")}>
                  <ListItemIcon>
                    <CalendarIcon />
                  </ListItemIcon>
                  <ListItemText primary="AGENDA" />
                </ListItemButton>

                <ListItemButton onClick={() => handlerNavigate("/localizacao")}>
                  <ListItemIcon>
                    <LocationOnIcon />
                  </ListItemIcon>
                  <ListItemText primary="LOCALIZAÇÃO" />
                </ListItemButton>

                <ListItemButton onClick={() => handlerNavigate("/contato")}>
                  <ListItemIcon>
                    <PhoneAndroidIcon />
                  </ListItemIcon>
                  <ListItemText primary="CONTATO" />
                </ListItemButton>
              </>
            ) : (
              <>
                <ListItemButton onClick={() => handlerNavigate("/agenda")}>
                  <ListItemIcon>
                    <CalendarIcon />
                  </ListItemIcon>
                  <ListItemText primary="AGENDA" />
                </ListItemButton>
              </>
            )}

            {/* <ListItemButton onClick={() => handlerNavigate("/estudos")}>
              <ListItemIcon>
                <BookIcon />
              </ListItemIcon>
              <ListItemText primary="ESTUDOS" />
            </ListItemButton>

            <ListItemButton onClick={() => handlerNavigate("/cursos")}>
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary="CURSOS" />
            </ListItemButton>

            <ListItemButton onClick={() => handlerNavigate("/fotos")}>
              <ListItemIcon>
                <PhotoAlbumIcon />
              </ListItemIcon>
              <ListItemText primary="FOTOS" />
            </ListItemButton> */}

            {/* <ListItemButton onClick={() => handlerNavigate("/login")}>
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="LOGIN" />
            </ListItemButton> */}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default AppDrawer;
