import styled from 'styled-components';

export const ContainerForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  width: 100%;
  margin-bottom: 16px;
`;

export const ContainerFormItens = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  width: 90%;
  margin-bottom: 16px;
`;

export const ContainerMessageSuccess = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const ContainerContactText = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;

  margin-top: 20px;
  margin-bottom: 00px;
`;