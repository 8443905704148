import React from 'react';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import CarouselDesktop from './CarouselDesktop';
import CarouselMobile from './CarouselMobile';

const Carousel: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            {matches ? <CarouselMobile /> : <CarouselDesktop />}
        </>
    );
}

export default Carousel;