import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
export const CheckboxItem = styled.label`
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;

    span {
        display: block;
        width: inherit;
        height: inherit;
        border: 1px solid ${props => props.theme.colors.checkbox.border};
        transition: all 0.375s;
        border-radius: 5px;
    }

    input {
        display: none;
        position: absolute;
        transform:  scale(0);
        border-color: #FFF;

        &:checked ~ span {
            transform: rotate(45deg);
            width: 15px;
            margin-left: 8px;
            margin-top: -4px;
            border-color: #24c78e;
            border-top-color: transparent;
            border-left-color: transparent;
            border-radius: 0;
        }
    }
`;

export const Label = styled.label`
    color: ${props => props.theme.colors.secondary};
    font-weight: 500;
    margin-left: 12px;
`