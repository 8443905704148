/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState } from "react";

import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
} from "@mui/material";

import { CalendarMonth as CalendarIcon } from "@mui/icons-material";

import api from "../../service";
import { Schedules } from "./ScheduleDesktop";
import {
  CalendarBody,
  CalendarContainer,
  CalendarDayMobile,
  CalendarHead,
  Container,
  ImageMobile,
  ListEvents,
  ListEventTitle,
  Root,
  Title,
} from "./styles";

const ScheduleMobile: React.FC = () => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [imagemSel, setImagemSel] = useState("");
  const [schedules, setSchedules] = useState<Schedules>({} as Schedules);

  const getAgenda = useCallback(async () => {
    const res = await api.get("site/schedule");
    setSchedules({
      now: res.data.now,
      days: res.data.days,
    });
  }, []);

  const showImagem = useCallback((imagem: string) => {
    setImagemSel(imagem);
    setModalShow(true);
  }, []);

  const handleClose = () => setModalShow(false);

  useEffect(() => {
    getAgenda();
  }, [getAgenda]);

  return (
    <Root>
      <Container>
        <Title>
          {schedules && schedules.now && schedules.now.toLocaleUpperCase()}
        </Title>
        <CalendarContainer>
          <CalendarHead>
            <CalendarDayMobile head>
              <span className="content">DOM</span>
            </CalendarDayMobile>
            <CalendarDayMobile head>
              <span className="content">SEG</span>
            </CalendarDayMobile>
            <CalendarDayMobile head>
              <span className="content">TER</span>
            </CalendarDayMobile>
            <CalendarDayMobile head>
              <span className="content">QUA</span>
            </CalendarDayMobile>
            <CalendarDayMobile head>
              <span className="content">QUI</span>
            </CalendarDayMobile>
            <CalendarDayMobile head>
              <span className="content">SEX</span>
            </CalendarDayMobile>
            <CalendarDayMobile head>
              <span className="content">SAB</span>
            </CalendarDayMobile>
          </CalendarHead>
          <CalendarBody>
            {schedules &&
              schedules.days &&
              schedules.days.map((schedule) => (
                <>
                  <CalendarDayMobile
                    today={schedule.today}
                    disabled={schedule.disabled}
                    event={!!schedule.events}
                  >
                    <span className="content">
                      {schedule.day}
                      {schedule.events && (
                        <>
                          {schedule.events.map((event, idx) => {
                            if (schedule.events.length > 2) {
                              if (idx < 1) {
                                return (
                                  <span className="event">{event.event}</span>
                                );
                              } else if (idx === 1) {
                                return (
                                  <span className="event">
                                    +{schedule.events.length - 1}
                                  </span>
                                );
                              }
                            } else {
                              return (
                                <span className="event">{event.event}</span>
                              );
                            }
                          })}
                        </>
                      )}
                    </span>
                  </CalendarDayMobile>
                </>
              ))}
          </CalendarBody>
        </CalendarContainer>

        <br />
        <ListEvents>
          <ListEventTitle>Lista de Eventos</ListEventTitle>

          <List
            sx={{ width: "100%", maxWidth: 1020, bgcolor: "background.paper" }}
          >
            {schedules &&
              schedules.days &&
              schedules.days.map((schedule) => {
                if (schedule.events == null) {
                  <>
                    <h1>{schedule.day}</h1>
                  </>;
                } else {
                  return (
                    <>
                      {schedule &&
                        schedule.events.map((event) => (
                          <>
                            <ListItem
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  aria-label="show"
                                  onClick={() => showImagem(event.image)}
                                >
                                  <CalendarIcon color="secondary" />
                                </IconButton>
                              }
                            >
                              <ListItemAvatar>
                                <img
                                  src={event.image}
                                  style={{ borderRadius: 2, marginRight: 16 }}
                                  width={140}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <h3 style={{ paddingBottom: "none" }}>
                                    {event.text}
                                  </h3>
                                }
                                secondary={
                                  <>
                                    <strong
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {event.date &&
                                        event.date.toLocaleUpperCase()}
                                    </strong>
                                    <br />
                                    <span
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {event.hour}
                                    </span>
                                    <br />
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {event.pass}
                                    </p>
                                  </>
                                }
                              />
                            </ListItem>
                          </>
                        ))}
                    </>
                  );
                }
              })}
          </List>
        </ListEvents>
      </Container>
      <div>
        <Modal
          open={modalShow}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
              onClick={() => setModalShow(false)}
            >
              <ImageMobile src={imagemSel} width={300} />
            </div>
          </Box>
        </Modal>
      </div>
    </Root>
  );
};

export default ScheduleMobile;
