import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useUIContext } from '../context/ui';

interface IRouteProps {
    isLogin?: boolean;
    isAdmin?: boolean;
    children: React.ReactElement;
}

const Route: React.FC<IRouteProps> = ({ children, isLogin, isAdmin }) => {
    const { isAuthenticated, setIsLogin, setIsAdmin } = useUIContext()

    useEffect(() => {
        if (isLogin)
            setIsLogin(isLogin)
        if (isAdmin)
            setIsAdmin(isAdmin)
    }, [isLogin, isAdmin, setIsLogin, setIsAdmin])

    if (isAuthenticated === false && !isLogin) {
        setIsLogin(false)
        setIsAdmin(false)
        return <Navigate to="/" />
    }
    return children;
}

export default Route;