import React from 'react';
import Carousel from '../../components/Carousel';
import Shortcuts from '../../components/Shortcuts';
import Schedule from '../Schedule';

const Home: React.FC = () => {
    return (
        <>
            <Carousel />
            <Shortcuts />
            <Schedule />
        </>
    );
}

export default Home;