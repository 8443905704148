import React, { useCallback, useState } from "react";

import {
  Alert,
  Button,
  CircularProgress,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { WhatsApp as WhatsAppIcon } from "@mui/icons-material";

import { useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import api from "../../service";
import {
  ContainerContactText,
  ContainerForm,
  ContainerFormItens,
  ContainerLoading,
  ContainerMessageSuccess,
} from "./styles";

const ContactDesktop: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false);
  const [messageSend, setMessageSend] = useState(false);

  const { register, handleSubmit } = useForm();

  const handleCreateContact = useCallback(async (dataForm: any) => {
    setLoading(true);
    console.log("handleCreateContact()->dataForm::", dataForm);
    const { data, status } = await api.post("/events/contact", dataForm);
    if (status === 204) setMessageSend(true);

    console.log("handleCreateContact()->data::", data);
    setLoading(false);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyItems: "center",
        alignContent: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          marginTop: 16,
        }}
      >
        <Typography
          variant={matches ? "h6" : "h4"}
          component="span"
          style={{ marginLeft: matches ? "-5px" : 20 }}
        >
          CONTATO
        </Typography>
        <ContainerContactText style={{ width: matches ? "90%" : "50%" }}>
          <div
            style={{
              display: "flex",
              justifyItems: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <strong>Sede:</strong>
            Rua Sol, 282 B - Jardim Tupanci <br />
            Barueri - SP - 06414-070
          </div>
          <div
            style={{
              display: "flex",
              justifyItems: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <strong>E-mail:</strong>
            contato@tendaogum7espadas.com.br
          </div>
        </ContainerContactText>

        <div
          style={{
            display: "flex",
            justifyItems: "center",
            alignContent: "center",
            flexDirection: "column",
            width: matches ? "90%" : "50%",
          }}
        >
          <Button
            variant="text"
            startIcon={<WhatsAppIcon />}
            style={{ color: "green", marginTop: 10, marginBottom: "10px" }}
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send?phone=5511990054606&text=Ol%C3%A1,%0AConheci%20voc%C3%AAs%20pelo%20site!",
                "_blank",
                "noreferrer"
              )
            }
          >
            Entre em contato por WhatsApp
          </Button>
          <strong style={{ fontWeight: 900, textAlign: "center" }}>
            Praticamos a Umbanda, os ensinamentos de Zélio Fernandino de Moraes
            e prezamos pelo livre arbítrio.
          </strong>
          <p style={{ textAlign: "justify" }}>
            <div style={{ marginBottom: 10 }}>
              Não realizamos cobrança por atendimentos, não jogamos búzios, não
              fazemos amarrações e não trabalhamos com sacrifício animal.
            </div>
            <div style={{ marginBottom: 10 }}>
              O melhor dia para conhecer a Casa é no Benzimento, que ocorre às
              segundas feiras, das 16h às 19h.
            </div>
            <div style={{ marginBottom: 10 }}>
              Acompanhe a nossa Agenda para verificar a disponibilidade da
              semana e venha nos visitar!
            </div>
            <div style={{ marginBottom: 10 }}>
              Teremos prazer em receber seu contato para dúvidas e sugestões.
            </div>
            <div style={{ marginBottom: 10 }}>
              Para entrar em contato conosco, utilize o formulário abaixo.
            </div>
          </p>
        </div>
      </div>

      {messageSend ? (
        <ContainerMessageSuccess>
          <Alert variant="standard" severity="success">
            Mensagem enviada com sucesso!!!
          </Alert>
        </ContainerMessageSuccess>
      ) : (
        <>
          {loading ? (
            <ContainerLoading>
              <CircularProgress size={22} color="success" />
              <Alert variant="standard" severity="info">
                Enviando sua mensagem...
              </Alert>
            </ContainerLoading>
          ) : (
            <>
              <form onSubmit={handleSubmit(handleCreateContact)}>
                <div
                  style={{
                    display: "flex",
                    justifyItems: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    marginTop: 8,
                  }}
                >
                  <ContainerForm>
                    <Typography
                      sx={{ ml: 1 }}
                      variant="h6"
                      component="span"
                      style={{ marginLeft: "-50px" }}
                    >
                      Duvidas/Sugestões
                    </Typography>
                    <ContainerFormItens>
                      <TextField
                        fullWidth
                        label="Nome"
                        placeholder="Nome"
                        variant="outlined"
                        required
                        InputLabelProps={{ shrink: true }}
                        {...register("name")}
                      />
                      <TextField
                        fullWidth
                        label="Celular"
                        placeholder="99 99999-9999"
                        variant="outlined"
                        required
                        InputLabelProps={{ shrink: true }}
                        {...register("mobile")}
                      />
                      <TextField
                        fullWidth
                        label="E-mail"
                        placeholder="E-mail"
                        variant="outlined"
                        required
                        InputLabelProps={{ shrink: true }}
                        {...register("email")}
                      />
                      <TextField
                        inputProps={{ maxLength: 200 }}
                        fullWidth
                        label="Mensagem"
                        placeholder="Mensagem"
                        variant="outlined"
                        required
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={4}
                        {...register("message")}
                      />
                      <Button
                        fullWidth
                        style={{
                          display: "flex",
                          justifyItems: "center",
                          alignContent: "center",
                        }}
                        variant="contained"
                        type="submit"
                      >
                        Enviar
                      </Button>
                    </ContainerFormItens>
                  </ContainerForm>
                </div>
              </form>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ContactDesktop;
