import { IconButton, List, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

import { Colors, DrawerWidth } from "../../layout/Themes/Theme01/theme";

export const AppbarContainer = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    height: 80,
    justifyContent: 'space-between',
    alignItems: 'center',
}));

export const AppbarLogo = styled(Box)(() => ({
    display: 'flex',
    width: 250,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',

}));

export const AppbarMenu = styled(Box)(() => ({
    display: 'flex',
    width: '80%',
    height: 80,
    justifyContent: 'flex-start',
    alignItems: 'center',
}));

export const AppbarMenuMobile = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    height: 80,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 8px',
}));

export const AppbarHeader = styled(Typography)(() => ({
    padding: "4px",
    flexGrow: 1,
    fontSize: "4em",
    fontFamily: '"cursive"',
    color: Colors.secondary
}));

export const ActionIconsContainerMobile = styled(Box)(() => ({
    display: 'flex',
    background: Colors.shaft,
    position: "fixed",
    bottom: 0,
    left: 0,
    width: '100%',
    alignItems: 'center',
    zIndex: 99,
    borderTop: `1px solid ${Colors.border}`
}));

export const ActionIconsContainerDesktop = styled(Box)(() => ({
    flexGrow: 0,
}));

interface MyListProps {
    type: string;
}

export const MyList = styled(List)<MyListProps>(({ type }) => ({
    display: type === "row" ? "flex" : "block",
    flexGrow: 3,
    justifyContent: "center",
    alignItems: "center",
}));



export const DrawerCloseButton = styled(IconButton)(() => ({
    position: 'absolute',
    top: 10,
    left: DrawerWidth,
    zIndex: 1999,
}));