
import styled from 'styled-components';
import theme, { Colors } from '../../layout/Themes/Theme01/theme';

export const Root = styled.div`
  width: auto;
  margin-left: ${theme.spacing} * 3;
  margin-right: ${theme.spacing} * 3;
`;

export const ContainerHead = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
  margin-bottom: 4px;
`;

export const OrixasText = styled.div`
  border: 1px solid;
  border-radius: 6px;
  width: 120px;
  text-align: center;
  padding: 8px;
  margin-left: 4px;

  cursor: pointer;

  &:hover {
    background-color: ${Colors.secondary};
    color: ${Colors.white};
  }
`;

export const CardDetails = styled.div``;


export const OraxaImage = styled.div`

`;